(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CalendarController',CalendarController);

    CalendarController.$inject = ['$rootScope', '$state',  '$timeout',  '$scope',   'Auth', 
                                  'Principal', 'AlertService','UserSearch', 'RedmineService', 'CalendarService'];

    function CalendarController ($rootScope,$state,$timeout,$scope,Auth,
    		Principal,AlertService,UserSearch,RedmineService,CalendarService){
    	
        var vm = this;
        // Initial values for minTime and maxTime
        vm.minTime = 5;
        vm.maxTime =20;
     //   vm.userTimezone = moment.tz.guess();
//        vm.minTime = new Date(1970, 0, 1, 5, 0, 0);//'05:00:00';
//        vm.maxTime = new Date(1970, 0, 1, 19, 0, 0);//'19:00:00';
        
        vm.listeArea = [];
        vm.users = UserSearch.searchUserArea({ offset: 0, size: 1000});
        vm.userSelected = [];        
        vm.tasks = [];

        vm.openModal = function(){
        	vm.modalTitle = "Ajouter un événement";
        	vm.eventData = {};
            $('#eventModal').modal('show');
            $timeout(function() {
            	$('#field_title').focus();
            }, 500);
        }

        function initCalendar() {
            vm.userSelected.forEach(function(user, index) {
            	$('#calendar-' + index).fullCalendar({
                    defaultView: 'agendaWeek',
                    editable: true,
                    allDaySlot: false, // Cache la partie all day en haut du calendrier vu semaine
                    droppable: true,
                    timezone: 'local',
                    selectable: true,
                    slotDuration: '00:30:00',
                    contentHeight:'auto',      
//                    minTime: vm.minTime.getHours()+":"+ vm.minTime.getMinutes()+":00",  // Initial minTime from model
//                    maxTime: vm.maxTime.getHours()+":"+ vm.minTime.getMinutes()+":00",  // Initial maxTime from model
                    minTime: vm.minTime+":00:00",  // Initial minTime from model
                    maxTime: vm.maxTime+":00:00",  // Initial maxTime from model
         //           header: false, // On masque l'entête
                    columnFormat: 'dddd',
                    header: {
	                  left: 'prev,next today',
	                  center: 'title',
	                  right: 'agendaWeek,agendaDay'//month, on cache month sinon on ne peut pas gérer les heures
                    },

                    //  Gestion du Drop (choix de la colonne utilisateur)
                    drop: function(date, jsEvent, ui) {
                       // var eventTitle = ui.helper.text().trim();
                        
                        var eventTitle = ui.helper.attr("data-title");
                        var eventDuration = parseInt(ui.helper.attr("data-duration"));
                        var eventIndex = parseInt(ui.helper.attr("data-index"));
                        var startDate = moment(date);
                        var endDate = moment(startDate).add(eventDuration, 'hours');
                        
                        // Vérifier si la tâche contient un `issue`
                        var task = vm.tasks[eventIndex];
                        var userData = user ? JSON.stringify(user) : null;
                        var uuidtp = uuidv4();
                        var colortmp = getColorByType( task.issue);
                        var event = {
                        		id:null,
                        		uuid:uuidtp,
                                title: eventTitle,
                                start: startDate,
                                end: endDate,
                                allDay: false,
                                color: colortmp,
                                user: userData,
                                teamworkId: task.issue.id 
                         }
                        
                         $('#calendar-' + index).fullCalendar('renderEvent', event, true);   
                        
                        // Chercher l'événement via son titre ou autre critère unique
                        var addedEvent = $('#calendar-' + index).fullCalendar('clientEvents', function(ev) {
                            return ev.uuid === event.uuid && ev.start.isSame(event.start);
                        });

                        // Si l'événement est trouvé, affiche son ID
                        if (addedEvent.length > 0) {
                            console.log('ID attribué par FullCalendar:'+ addedEvent[0]._id);
                            event._id = addedEvent[0]._id;
                        }
                     
                        $scope.$apply(function () {
                            vm.tasks.splice(eventIndex,1 );
                            vm.filterTasks();
                        });  
                        vm.updateEvent(event,index);
                    },
                    select: function(datedeb, datefin ) {
                    	vm.modalTitle = "Ajouter un événement";
                        $scope.$apply(function () {
	                    	vm.eventData = {};
	                        if ( datedeb) {
	                        	vm.eventData.start = new Date( datedeb); // Convertit la chaîne en objet Date
	                        }
	                        if ( datefin) {
	                    	   vm.eventData.end = new Date(datefin);
	                        }
	                        vm.eventData.user = user;
	                        vm.eventData.indexCalendar = index;
	                       
	                        $('#eventModal').modal('show');        
	                        $timeout(function() {
	                        	$('#field_title').focus();
	                        }, 500);
                        });  
                    },
                    events: function(start, end, timezone, callback) {
                    	vm.loadEvents(user,start, end, callback);
                    },
                    eventDrop: function(event) {
                        vm.updateEvent(event,index);
                    },
                    eventResize: function(event) {
                        vm.updateEvent(event,index);
                    },
                    eventClick: function(event) {
                        $scope.$apply(function() {
                        	
                            vm.modalTitle = "Modifier l'événement";
                            vm.eventData = angular.copy(event);
                            if ( vm.eventData.start) {
                            	 vm.eventData.start = new Date( vm.eventData.start); // Convertit la chaîne en objet Date
                            }
                            if ( vm.eventData.end) {
                            	 vm.eventData.end = new Date( vm.eventData.end);
                            }
                            vm.eventData.indexCalendar = index;
                            $('#eventModal').modal('show');
                        });
                    },
                    eventDragEnter: function (event, jsEvent, ui) {
                        $(".fc-highlight").css("background-color", "#ffcc80");
                    },
                    eventDragLeave: function (event, jsEvent, ui) {
                        $(".fc-highlight").css("background-color", "");
                    },
                    eventReceive: function (event) {
                        var eventElement = $("div.fc-event:contains('" + event.title + "')");
                        eventElement.addClass("highlight-event");

                        setTimeout(function () {
                            eventElement.removeClass("highlight-event");
                        }, 1000);
                        
                    },
                    eventRender: function(event, element) {
                        // Ajoute un bouton pour supprimer l'événement
                        var deleteBtn = $('<br/><span class="delete-event" style="cursor: pointer; margin-left: 5px;">❌</span>');
                        element.find('.fc-title').append(deleteBtn);
                        
                        if(event.teamworkId){
                        	  element.find('.fc-title').prepend('<a style="color:white;" href="'+event.teamworkUrl+'" target="_blank">#'+event.teamworkId+' </a> ');
                        }
                        
                        
                        element.css('background-color', getColorByType( event.teamworkId));
                        
                        // Supprime l'événement au clic et le remet dans la liste
                        deleteBtn.on('click', function () {
                        	 $('#calendar-' + index).fullCalendar('removeEvents', event._id);

               
                             CalendarService.deleteEvent({id:event.id},function(response) {},onError );
                             if(event.teamworkId){
      		                	$scope.$apply(function () {
      		                		$timeout(vm.loadAllIssues, 500);
      		                	});
      		                }
                        });
                    }
                });
//                setTimeout(function() {
//                    $('#calendar-' + index).fullCalendar('render');
//                }, 100);

                // Appeler cette fonction après l'initialisation des calendriers
                setTimeout(vm.syncCalendars, 500);
            });

      
        }
        
        function initTaskDroppable() {

            //  Rendre les tâches draggable
            $('.task').each(function () {
                $(this).data('event', {
                    title: $(this).text(),
                    duration: parseInt($(this).attr('data-duration')),
                    stick: true
                }).draggable({
                    zIndex: 999,
                  //  revert: true,
                    revertDuration: 100,
                    revert: "invalid",
                    helper: "clone",  // Permet d'afficher un clone et non l'élément original
                    start: function(event, ui) {
                        $(this).addClass("dragging");
                    },
                    stop: function(event, ui) {
                        $(this).removeClass("dragging");
                    }
                });
            });
        }
        
        // Fonction pour obtenir une couleur unique par user
        function getUserColor(user) {
            var colors = ["#ffadad", "#9bf6ff", "#ffd6a5", "#0033cc", "#337ab7"];
            return colors[vm.users.indexOf(user) % colors.length];
        }
        
        function getColorByType(issue) {
        	if(issue){
        		return "#0033cc";
        	}else{
        		return "#337ab7";
        	}
        }
        
        
        vm.showCalendar = function (){
        	$timeout(initCalendar, 200);
            $timeout(initTaskDroppable, 200);
    	}
        
        // Charger les événements pour les utilisateurs sélectionnés
        vm.loadEvents = function(user,start, end, callback) {
            if (vm.userSelected.length === 0) {
                callback([]); // Aucune sélection, aucun événement
                return;
            }
            var listId = [];
        	listId.push(user.id);
            CalendarService.findEvents({
                usersId: listId.join(','), 
                from: moment(start.toISOString()).toISOString(true),
                to: moment(end.toISOString()).toISOString(true)
            },function(response) {
            	if(response && response.length>0){
            		callback(response);
            	}
            },onError);
        };
        

        // Mettre à jour un événement après un déplacement ou un redimensionnement
        vm.updateEvent = function(event,indexFullCalendar) {
        	console.log("appel updateEvent eventId:"+event.id +" _id:"+event._id);
        	var user= {};
        	if (typeof event.user === 'string' || event.user instanceof String){
        		 user= event.user ? JSON.parse(event.user) : null;
        	}else{
        		user = event.user;
        	}
        
        	
            var updatedEvent = {
                id: event.id,
                title: event.title,
                comment: event.comment,
                address: event.address,
                start:  moment(event.start.toISOString()).toISOString(true),
                end:  moment(event.end.toISOString()).toISOString(true),
                user: user,
                teamworkId : event.teamworkId
            };
            CalendarService.saveEvent(updatedEvent,
            		function(response) {
            			console.log("réponse updateEvent "+response.id+" eventId:"+event.id +" _id:"+event._id);
		            	if(response && response.id ){
		                	console.log("réponse updateEvent "+response.id+" eventId:"+event.id +" _id:"+event._id);
		            		event.id = response.id;
		            		

		            		if( event._id){
			            	  $('#calendar-' + indexFullCalendar).fullCalendar('removeEvents', event._id);
		            		}
		            		$('#calendar-' + indexFullCalendar).fullCalendar('renderEvent', event);

		            	}
            		},onError
            
            );
        };

        
        
      //  $timeout(initTaskDroppable, 0);
        

//        // Watch the minTime and maxTime models for changes
//        $scope.$watchGroup(['minTime', 'maxTime'], function(newValues) {
//          // Update minTime and maxTime dynamically
//
//            vm.users.forEach(function(user, index) {
//                $('#calendar-' + index).fullCalendar().setOption('minTime', newValues[0]); // minTime
//                $('#calendar-' + index).setOption('maxTime', newValues[1]); // maxTime
//                $('#calendar-' + index).render();  // Re-render the calendar with updated options
//          });
//        });
//			FIXME finir ça. 

        
        vm.saveEvent = function () {
        	vm.isSaving=true;
            var eventToSave = {
            		id:vm.eventData.id,
            		uuid:uuidv4(),
                    title: vm.eventData.title,
                    comment: vm.eventData.comment,
                    address: vm.eventData.address,
                    start: vm.eventData.start,
                    end: vm.eventData.end,
                    allDay: false,
                    user: vm.eventData.user, 
                    teamworkId: vm.eventData.teamworkId 
                }
            
            
            CalendarService.saveEvent(eventToSave,
            		function(response) {
            			vm.isSaving=false;
		            	if(response && response.id ){
		            		if(vm.eventData.indexCalendar){
                            	$('#calendar-' + vm.eventData.indexCalendar).fullCalendar('refetchEvents');
		            		}else{
                                vm.userSelected.forEach(function(user, index) {
                                	$('#calendar-' + index).fullCalendar('refetchEvents');
                                	}
                                );
		            		}
		                    $('#eventModal').modal('hide');
//                                $scope.$apply(function () {
//                                	vm.showCalendar();
//                                });
		            	}
            		},onError
                
                );
        };

        vm.deleteEvent = function () {
            CalendarService.deleteEvent({id:vm.eventData.id},
            		function(response) {
	            		if(vm.eventData.teamworkId){
			                $timeout(vm.loadAllIssues, 200);
		                }
            		},onError
                
                );

       	 	$('#calendar-' + vm.eventData.indexCalendar).fullCalendar('removeEvents', vm.eventData._id);

            $('#eventModal').modal('hide');

 
        };

        
        
        vm.user = {} ;
        Principal.identity().then(function(account) {
        	if(account){
              vm.user =account ;
              if(account.agencesRattachement){
            	  vm.listeArea = account.agencesRattachement;
              }
        		
        	}
        });

        vm.loadAllIssues =  function () {
        	RedmineService.issuesNotAssigned({
                page: 0,
                size: 100}, onSuccessLoadAllIssues, onError);
    	
        }

        vm.loadAllIssues();
        
        function onSuccessLoadAllIssues(data, headers) {
            vm.totalItems = data.total_count;
            vm.queryCount = vm.totalItems;
            vm.displayedTasks = [];
            vm.tasks = [];
            vm.uniqueTrackers = [];
            vm.listIssues = data;

           	
           	vm.listIssues.issues.forEach(function(issue, index) {
           		var duration = issue.estimated_hours ?issue.estimated_hours :1;
           		vm.tasks.push({ label: issue.subject, duration: duration,issue:issue });
                if (vm.uniqueTrackers.indexOf(issue.tracker.name) === -1) {
                	vm.uniqueTrackers.push(issue.tracker.name);
                }
           	});
            vm.displayedTasks = angular.copy(vm.tasks);
            $timeout(initTaskDroppable, 200);
            vm.filterTasks();
            
        }
        
        // Extraction des trackers uniques 
        vm.uniqueTrackers = [];

        // Liste des trackers sélectionnés
        vm.selectedTrackers = [];
        
        // Fonction de filtrage
        vm.filterTasks = function() {
            if (vm.selectedTrackers.length === 0) {
            	vm.displayedTasks = angular.copy(vm.tasks); // Afficher toutes les tâches
            } else {
            	vm.displayedTasks = vm.tasks.filter(function(task) {
                    return vm.selectedTrackers.indexOf(task.issue.tracker.name) !== -1;
                });
            }
            $timeout(initTaskDroppable, 200);
        };
        
        function onError(error) {
        	//console.log(error);
			vm.isSaving=false;
            AlertService.error(error.data.message);
        }
        
        function uuidv4() {
        	  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, function(c){
        	   return  (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16);}
        	  );
        }
        
        
        /** Ca c'est pour synchronisé les boutons week day et changement de jour entre tous les calendriers. */
        vm.syncCalendars = function () {
            $('.user-column').each(function (index) {
                var calendar = $('#calendar-' + index);

//                // Synchroniser changement de vue (mois/semaine/jour)
//                calendar.fullCalendar('option', 'viewRender', function (view) {
//                    $('.user-column').each(function (i) {
//                        if (i !== index) {
//                            $('#calendar-' + i).fullCalendar('changeView', view.name);
//                        }
//                    });
//                });

                // Synchroniser navigation entre semaines/jours/mois
                calendar.fullCalendar('option', 'viewRender', function (view, element) {
                	var currentDate = calendar.fullCalendar('getDate');
                    $('.user-column').each(function (i) {
                        if (i !== index) {
                            $('#calendar-' + i).fullCalendar('gotoDate', currentDate);
                            $('#calendar-' + i).fullCalendar('changeView', view.name);
                        }
                    });
                });
            });
        };

    
        
	    vm.printCalendar = function() {
	        
	        if (vm.userSelected.length === 0) {
                callback([]); // Aucune sélection, aucun événement
                return;
            }
	        var listId = vm.userSelected.map(function(user) {return user.id;}); // Liste des IDs des utilisateurs sélectionnés
	        var start = moment().startOf('week'); // Début de la semaine actuelle
	        var end = moment().endOf('week'); // Fin de la semaine actuelle

            CalendarService.findEvents({
                usersId: listId.join(','), 
                from: moment(start.toISOString()).toISOString(true),
                to: moment(end.toISOString()).toISOString(true)
            },function(response) {
            	if(response && response.length>0){
                    generatePrintableCalendar(response);
            	}
            },onError);

	    };

	    function getRandomColor() {
	        return '#' + Math.floor(Math.random()*16777215).toString(16);
	    }
	    
	    function generatePrintableCalendar(events) {
	        var printWindow = window.open('', '_blank');
	        
	        
	        var userColors = {}; // Map UserId => Couleur
	        var availableColors = [ 
	            "#FF5733", "#33FF57", "#3357FF", "#F39C12", "#8E44AD", 
	            "#D35400", "#1ABC9C", "#C0392B", "#2C3E50", "#27AE60"
	        ]; // 10 couleurs prédéfinies
	        

	        var colorIndex = 0;
	        // Assigner une couleur unique à chaque utilisateur
	        events.forEach(function(event) {
	            if (!userColors[event.user.id]) {
	                userColors[event.user.id] = availableColors[colorIndex % availableColors.length];
	                colorIndex++;
	            }
	        });

	        var style = '<style>'/*
	              +'  body { font-family: Arial, sans-serif; margin: 10px; }'
	              +'  #calendar-print { max-width: 100%; margin: auto; }'
	              +' .fc-event { font-size: 12px; padding: 5px; border-radius: 4px; color: white; }'
	              +' .fc-day-grid-event { border-radius: 4px; }'
	              +' .fc-toolbar { display: none; }'
	              +' @media print {'
	              +'     body { margin: 0; }'
	              +'    .visible-print  { display: inherit !important; }' 
	              +'    .hidden-print   { display: none !important; }' 
	              +' }'*/
	              +'</style>';

	        printWindow.document.write('<html><head>'
	        		+'<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.10.2/fullcalendar.min.css">'
	                +'<script src="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.10.2/fullcalendar.min.js"></script> '
	        		+'<link href="//cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.10.2/fullcalendar.print.css " rel="stylesheet" type="text/css" media="print" />' 
//	        		+'<link href="//cdnjs.cloudflare.com/ajax/libs/fullcalendar/1.6.4/fullcalendar.css" rel="stylesheet" type="text/css" />' 
//	        		+'<link href="//cdnjs.cloudflare.com/ajax/libs/fullcalendar/1.6.4/fullcalendar.print.css " rel="stylesheet" type="text/css" media="print" />' 
	        		+'<title>Impression Planning</title>' + style + '</head>'
	        //		+'<script type="text/javascript">  $(\'.printBtn\').on(\'click\', function (){   window.print(); });</script>'
	        		+'<body>');
	       // printWindow.document.write('<button class="printBtn hidden-print">Print</button><div id="calendar-print"></div>');
	        	printWindow.document.write('<div id="calendar-print"></div>');
	        printWindow.document.write('</body></html>');
	        printWindow.document.close();

	        setTimeout(function() {
	            var calendarElement = printWindow.document.getElementById('calendar-print');

	            $(calendarElement).fullCalendar({
            	  customButtons: {
            		    myCustomButton: {
            		      text: 'Print !',
            		      click: function() {
            		    	  printWindow.print(); 
            		      }
            		    }
            		  },
	                header: {
		                  left: ' myCustomButton',//prev,next today
	                    center: 'title',
		                  right: 'agendaWeek,agendaDay'
	                },
                    minTime: vm.minTime+":00:00",  // Initial minTime from model
                    maxTime: vm.maxTime+":00:00",  // Initial maxTime from model
	                defaultView: 'agendaWeek',//
	                editable: false,
	                slotEventOverlap: false, // Éviter le chevauchement
	                eventOverlap: true,
	                events: events.map(function(event){
	                	return {
	                    title: event.title+' ('+event.user.firstName+' '+event.user.lastName+')',
	                    start: event.start,
	                    end: event.end,
	                    color: userColors[event.user.id]  // Appliquer la couleur assignée
	                };})
	            });

	         //   printWindow.print();
	           // printWindow.close();
	        }, 500);
	    }

    
    
    


    }
})();
